<template>
	<van-popover v-model:show="this.showLangPopover"
				 :actions="this.langs_list"
				 placement="bottom-start"
				 @select="(option) => this.setLang(option.value)">
		<template #reference>
			<van-button plain
						hairline
						is-link
						type="white"
						size="small"
						style="display: inline-flex; margin: 1rem auto auto 1rem; color: #ffffff; background: transparent; text-transform: uppercase;"
						:icon="require('@/assets/icons/globe-white.svg')">
				{{ this.$root.locale }}
			</van-button>
		</template>
	</van-popover>
	<router-view />
</template>

<script>
import { ref } from 'vue';

export default {
	name: "welcomeLayout",
	data() {
		return {
			showLangPopover: ref(false),
			langs_list: this.$root.intl.langs,
		}
	},
	methods: {
		setLang(lang) {
			this.emitter.emit("setLang", lang);
		}
	},
	mounted() {
		document.body.classList.remove('main-layout');
		document.body.classList.add('welcome-layout');
	},
	components: {

	},
	computed: {

	}
};
</script>

<style lang="scss">

</style>