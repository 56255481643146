import { createStore } from "vuex";
import Session from "@/services/Session";
import CommonService from "@/services/CommonService";

const store = createStore({
	state() {
		return {
			token: (Session.get('token')) ? Session.get('token') : null,
			settings: (Session.get('settings')) ? Session.get('settings') : {},
		}
	},
	mutations: {
		setToken(state, data) {

			state.token = data;
			Session.set('token', state.token);

			if (process.env.NODE_ENV == "development")
				console.log('setToken()', state.token);

		},
		setSettings(state, data) {

			state.settings = data;
			Session.set('settings', state.settings);

			if (process.env.NODE_ENV == "development")
				console.log('setSettings()', state.settings);

		},
	},
	actions: {

	},
	getters: {
		getToken: state => state.token,
		getSettings: state => state.settings,
	}
});

export default store;