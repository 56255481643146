<template>
	<NavBar :title="this.title"
			:onPrevClick="this.prevClick"
			:onNextClick="() => this.sidebar_is_show = !this.sidebar_is_show"
			:leftIcon="'arrow-left'"
			:rightIcon="'setting-o'"
			:border="false" />

	<router-view />
	
	<van-back-top />
	
	<Sidebar :is-show="this.sidebar_is_show"
			 @sidebarClick="(index) => this.sidebar_is_show = false"
			 @sidebarChange="(index) => this.sidebar_is_show = false" />
	
</template>

<script>
import NavBar from "@/components/common/NavBar";
import Sidebar from "@/components/common/Sidebar";
export default {
    name: "mainLayout",
	props: {
		pageTitle: { type: String },
	},
	data() {
		return {
			sidebar_is_show: false,
		}
	},
	methods: {
		prevClick(event) {
			console.info('!!!!Nav::onClickLeft', event);
			this.$router.go(-1);
		},
		nextClick(event) {
			console.info('!!!!Nav::onClickRight', event);
		},
	},
	mounted() {
		document.body.classList.remove('welcome-layout');
		document.body.classList.add('main-layout');
	},
	components: {
		Sidebar,
		NavBar

    },
	computed: {
		title() {
			return (typeof this.$props.pageTitle !== "undefined") ? this.$props.pageTitle : null;
		},
	}
};
</script>

<style lang="scss">

</style>