<template>
	<div>

		<van-sidebar v-model="this.active"
					 @change="this.onChange"
					 class="nav-sidebar sidebar-right"
					 :class="{ 'show': this.sidebarIsShow }">

			<van-sidebar-item title="Main"
							  @click="(index) => this.onClick(index, '/', false)" />

			<van-sidebar-item title="Profile"
							  @click="(index) => this.onClick(index, '/profile', false)" />

			<van-sidebar-item title="Settings"
							  @click="(index) => this.onClick(index, '/settings', false)" />

			<van-sidebar-item title="Messages"
							  @click="(index) => this.onClick(index, '/messages', false)" />

		</van-sidebar>

		<van-overlay :show="this.sidebarIsShow"
					 @click="this.sidebarIsShow = false" />

	</div>
</template>

<script>
import CommonService from "@/services/CommonService";
import {useCurrentLang} from "vant";
import {ref} from "vue";

export default {
	name: "Sidebar",
	props: {
		isShow: {type: Boolean, default: false},
	},
	data() {
		return {
			active: ref(0),
		}
	},
	methods: {
		setPageTitle(title) {
			CommonService.setPageTitle(title);
			this.emitter.emit("setPageTitle", title);
		},
		isEmpty(data) {
			return CommonService.isEmpty(data);
		},
		toRoute(path, refresh) {

			if (refresh)
				window.location.href = path
			else
				this.$router.push(path);

		},
		translate(string, lang) {

			if (typeof lang !== "undefined") {
				if (!this.isEmpty(this.translations[lang])) {
					if (!this.isEmpty(this.translations[lang][string])) {
						return this.translations[lang][string];
					}
				}
			}

			return string;
		},
		onClick(index, path, refresh) {
			console.info({index: index, path: path, refresh: refresh});
			this.toRoute(path, refresh);
		},
		onChange(index) {
			setTimeout(() => this.$emit('sidebarChange', index), 200);
		}
	},
	watch: {
		'sidebarIsShow': function(val, oldVal) {

			if (val)
				document.body.classList.add('overflow-0', 'scroll-0');
			else
				document.body.classList.remove('overflow-0', 'scroll-0');

		},
	},
	computed: {
		sidebarIsShow: {
			get() {
				return (typeof this.$props.isShow !== "undefined") ? this.isShow : false;
			},
			set(value) {
				value = Boolean(value);
			}
		},
	},
}
</script>

<style lang="scss" scoped>
.nav-sidebar {
	position: fixed;
	height: 100%;
	width: 0;
	top: 0;
	bottom: 0;
	background-color: #111;
	overflow-x: hidden;
	padding-top: 47px;
	transition: 0.3s;
	z-index: 4;
	&.sidebar-left {
		left: -100%;
		right: auto;
	}
	&.sidebar-right {
		left: auto;
		right: -100%;
	}
	&.show {
		width: 80vw;
		&.sidebar-left {
			left: 0;
			right: auto;
		}
		&.sidebar-right {
			left: auto;
			right: 0;
		}
	}
}
</style>