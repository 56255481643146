<template>
	<van-nav-bar :title="this.title"
				 :left-text="this.leftText"
				 :right-text="this.rightText"
				 :left-arrow="this.isLeftArrow"
				 :right-arrow="this.isRightArrow"
				 @click-left="this.onClickLeft"
				 @click-right="this.onClickRight"
				 :border="this.border"
				 :fixed="this.fixed"
				 :placeholder="this.placeholder"
				 :safe-area-inset-top="this.safeArea"
				 :clickable="this.clickable"
				 :z-index="this.zIndex">
		<template v-if="!this.isEmpty(this.leftSlotIcon)" #left>
			<van-icon :name="this.leftSlotIcon" size="22" />
		</template>
		<template v-if="!this.isEmpty(this.rightSlotIcon)" #right>
			<van-icon :name="this.rightSlotIcon" size="22" />
		</template>
	</van-nav-bar>
</template>

<script>
import CommonService from "@/services/CommonService";

export default {
	name: "NavBar",
	props: {
		onPrevClick: {
			type: Function,
			default: () => {},
		},
		onNextClick: {
			type: Function,
			default: () => {},
		},
		leftArrow: {type: Boolean},
		rightArrow: {type: Boolean},
		leftIcon: {type: String},
		rightIcon: {type: String},
	},
	data() {
		return {
			title: null,
			leftText: null,
			rightText: null,
			isLeftArrow: (typeof this.$props.leftArrow !== "undefined") ? this.leftArrow : false,
			isRightArrow: (typeof this.$props.rightArrow !== "undefined") ? this.rightArrow : false,
			leftSlotIcon: (typeof this.$props.leftIcon !== "undefined") ? this.leftIcon : null,
			rightSlotIcon: (typeof this.$props.rightIcon !== "undefined") ? this.rightIcon : null,
			border: true,
			fixed: true,
			placeholder: false,
			safeArea: false,
			clickable: false,
			zIndex: 5,
		}
	},
	methods: {
		isEmpty(data) {
			return CommonService.isEmpty(data);
		},
		onClickLeft(event) {

			console.info('Nav::onClickLeft', event);

			if (typeof this.$props.onPrevClick != "undefined")
				return this.$props.onPrevClick(event);

			history.back();
		},
		onClickRight(event) {

			console.info('Nav::onClickRight', event);

			if (typeof this.$props.onNextClick != "undefined")
				return this.$props.onNextClick(event);

		},
	},
	mounted() {

	}
}
</script>

<style scoped lang="scss">

</style>