import { Locale } from 'vant';

const messages = {
	'pl-PL': {
		vanPicker: {
			confirm: ' ЗАКРЫТЬ',
		},
	},
};

export default Locale.add(messages);