import { createWebHistory, createRouter } from "vue-router";

const routes =  [
  {
    path: "/",
    name: "main",
    component: () => import("./pages/Main")
  }, {
    path: "/verification",
    name: "verification",
    component: () => import("./pages/Verification")
  }, {
    path: "/sign-in",
    name: "sign-in",
    component: () => import("./pages/SignIn")
  }, {
    path: "/sign-up",
    name: "sign-up",
    component: () => import("./pages/SignUp")
  }, {
    path: "/messages",
    name: "messages",
    component: () => import("./pages/Messages")
  }, {
    path: "/profile",
    name: "profile",
    component: () => import("./pages/Profile")
  }, {
    path: "/settings",
    name: "settings",
    component: () => import("./pages/Settings")
  },
];

const router = createRouter({
  history: createWebHistory('/'),
  routes,
  scrollBehavior (to) {
    /*if (to.hash) {
      console.info(to);
      document.getElementById(to.hash).scrollIntoView();
      CommonService.debounce(document.getElementById(to.hash).scrollIntoView(), 5000);
      return {
        selector: to.hash
      }
    }*/
    document.getElementById('app').scrollIntoView({
      behavior: 'smooth',
      block: 'start',
    });
  },
});

export default router;