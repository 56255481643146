export default {
	"en-US": {
		...require('@/data/translations/en-US.json'),
		...require('@/data/translations/en-US_part2.json'),
	},
	"de-DE": {
		...require('@/data/translations/de-DE.json'),
		...require('@/data/translations/de-DE_part2.json'),
	},
	"uk-UA": {
		...require('@/data/translations/uk-UA.json'),
		...require('@/data/translations/uk-UA_part2.json'),
	},
	"ru-RU": {
		...require('@/data/translations/ru-RU.json'),
		...require('@/data/translations/ru-RU_part2.json'),
	},
	"es-ES": {
		...require('@/data/translations/es-ES.json'),
		...require('@/data/translations/es-ES_part2.json'),
	},
	"fr-FR": {
		...require('@/data/translations/fr-FR.json'),
		...require('@/data/translations/fr-FR_part2.json'),
	},
	"pl-PL": {
		...require('@/data/translations/pl-PL.json'),
		...require('@/data/translations/pl-PL_part2.json'),
	},
	"pt-BR": {
		...require('@/data/translations/pt-BR.json'),
		...require('@/data/translations/pt-BR_part2.json'),
	},
	"it-IT": {
		...require('@/data/translations/it-IT.json'),
		...require('@/data/translations/it-IT_part2.json'),
	},
	"hi-IN": {
		...require('@/data/translations/hi-IN.json'),
		...require('@/data/translations/hi-IN_part2.json'),
	},
	"zh-CN": {
		...require('@/data/translations/zh-CN.json'),
		...require('@/data/translations/zh-CN_part2.json'),
	},
	"ja-JP": {
		...require('@/data/translations/ja-JP.json'),
		...require('@/data/translations/ja-JP_part2.json'),
	},
};