<template>
	<van-config-provider :theme="theme" 
						 :style="(!this.usedWelcomeLayout) ? 'padding-top: 42px' : null">

<!--		<van-dropdown-menu>
			<van-dropdown-item
				v-model="this.locale"
				:options="this.intl.langs" />
		</van-dropdown-menu>-->

		<WelcomeLayout v-if="this.usedWelcomeLayout" />
		<MainLayout v-else :pageTitle="this.title" />

		<van-space>
			<van-button v-if="this.appUpdateExists" @click="this.refreshApp">
				New version available! Click to update
			</van-button>
			<div v-else>There's no update yet.</div>
		</van-space>

	</van-config-provider>
</template>

<script>
import { defineComponent, ref, h } from 'vue'
import { Locale } from 'vant';
import enUS from 'vant/es/locale/lang/en-US';
import deDE from 'vant/es/locale/lang/de-DE';
import ukUA from 'vant/es/locale/lang/uk-UA';
import ruRU from 'vant/es/locale/lang/ru-RU';
import ptBR from 'vant/es/locale/lang/pt-BR';
import zhCN from 'vant/es/locale/lang/zh-CN';
import esES from 'vant/es/locale/lang/es-ES';
import plPL from '@/data/locale/pl-PL';
import frFR from 'vant/es/locale/lang/fr-FR';
import itIT from 'vant/es/locale/lang/it-IT';
import hiIN from 'vant/es/locale/lang/hi-IN';
import jaJP from 'vant/es/locale/lang/ja-JP';

import translations from '@/data/translations';
import WelcomeLayout from "@/layouts/WelcomeLayout";
import MainLayout from '@/layouts/MainLayout';
import CommonService from '@/services/CommonService';

import tickSfx from '@/assets/audio/tick.mp3';
import cameraSfx from '@/assets/audio/camera.mp3';

import { showSuccessToast, showFailToast, showLoadingToast, showToast, showNotify } from 'vant';

const theme = ref('light');

export default defineComponent({
	name: "App",
	data () {
		return {
			title: '69d.me',
			//locale: 'en',
			locale: 'ru',
			intl: {
				langs: [
					{
						text: "EN",
						icon: require('circle-flags/flags/us.svg'),
						value: "en",
					}, {
						text: "DE",
						icon: require('circle-flags/flags/de.svg'),
						value: "de",
					},{
						text: "PL",
						icon: require('circle-flags/flags/pl.svg'),
						value: "pl",
					}, {
						text: "FR",
						icon: require('circle-flags/flags/fr.svg'),
						value: "fr",
					}, {
						text: "IT",
						icon: require('circle-flags/flags/it.svg'),
						value: "it",
					}, {
						text: "UK",
						icon: require('circle-flags/flags/ua.svg'),
						value: "uk",
					}, {
						text: "RU",
						icon: require('circle-flags/flags/ru.svg'),
						value: "ru",
					}, {
						text: "PT",
						icon: require('circle-flags/flags/br.svg'),
						value: "pt",
					}, {
						text: "ES",
						icon: require('circle-flags/flags/es.svg'),
						value: "es",
					}, {
						text: "ZH",
						icon: require('circle-flags/flags/cn.svg'),
						value: "zh",
					},{
						text: "HI",
						icon: require('circle-flags/flags/in.svg'),
						value: "hi",
					}, {
						text: "JA",
						icon: require('circle-flags/flags/jp.svg'),
						value: "ja",
					},
				],
				lang: ref('en-US'),
				locale: ref(enUS),
				translations: {}
			},
			settings: {
				color_scheme: ['auto'],
				mute_ui_sound: false,
				sound_level: 0.5,
				screen_notify: true,
				use_pin_code: false,
				pin_code: '',
				use_device_auth: false,
			},
			sounds: [],
			refreshing: false,
			worker: null,
			appUpdateExists: false,
		}
	},
	components: {
		WelcomeLayout,
		MainLayout,
	},
	created() {

		// Listen for swUpdated event and display refresh snackbar as required.
		document.addEventListener("swUpdated", this.showRefreshUI, { once: true });

		// Refresh all open app tabs when a new service worker is installed.
		navigator.serviceWorker && navigator.serviceWorker.addEventListener(

			//triggered by registration.claim
			"controllerchange",
			() => {

				if (this.refreshing)
					return;

				this.refreshing = true;

				console.log("controllerchange triggered, -> auto refresh!!");
				window.location.reload();
			}
		);
	},
	setup() {

		if (window.matchMedia && window.matchMedia('(prefers-color-scheme: light)').matches) {
			console.log('Prefers color: light scheme');
			theme.value = 'light';
		} else if (window.matchMedia && window.matchMedia('(prefers-color-scheme: dark)').matches) {
			console.log('Prefers color: dark scheme');
			theme.value = 'dark';
		}
		CommonService.setScheme(theme.value);

		window.matchMedia('(prefers-color-scheme: dark)').addEventListener('change', (event) => {
			const scheme = event.matches ? "dark" : "light";
			console.log('Prefers color changed: ' + scheme + ' scheme');
			theme.value = scheme;
			CommonService.setScheme(theme.value);
		});

		return {
			enUS,
			deDE,
			ptBR,
			ukUA,
			ruRU,
			zhCN,
			esES,
			plPL,
			frFR,
			itIT,
			hiIN,
			jaJP,
			theme
		}
	},
	methods: {
		isEmpty(data) {
			return CommonService.isEmpty(data);
		},
		inArray(needle, haystack) {
			return CommonService.inArray(needle, haystack);
		},
		getTranslations(locale) {
			console.info({
				locale: locale,
			});
			return translations[locale];
		},
		getSounds(files) {
			let _this = this;
			return new Promise(function(resolve, reject) {
				let list = [];
				files.forEach(file => {
					var temp = {};
					temp[file] = new Audio(require('@/assets/audio/' + file + '.mp3'));
					list = {...list, ...temp};
				});
				resolve(list);
			})
		},
		playSound(name = null, volume = 1.0) {

			console.info('playSound()', {
				name,
				volume
			});

			if (name) {
				if (this.sounds[name]) {
					let sfx = this.sounds[name].cloneNode();
					if (sfx) {
						sfx.volume = volume;
						sfx.play();
					}
				}
			}
		},
		setLang(lang) {

			switch (lang) {
				case 'uk':
					this.intl.lang = 'uk-UA';
					this.intl.locale = ukUA;
					break;

				case 'ru':
					this.intl.lang = 'ru-RU';
					this.intl.locale = ruRU;
					break;

				case 'en':
					this.intl.lang = 'en-US';
					this.intl.locale = enUS;
					break;

				case 'es':
					this.intl.lang = 'es-ES';
					this.intl.locale = esES;
					break;

				case 'de':
					this.intl.lang = 'de-DE';
					this.intl.locale = deDE;
					break;

				case 'fr':
					this.intl.lang = 'fr-FR';
					this.intl.locale = frFR;
					break;

				case 'pl':
					this.intl.lang = 'pl-PL';
					this.intl.locale = plPL;
					break;

				case 'it':
					this.intl.lang = 'it-IT';
					this.intl.locale = itIT;
					break;

				case 'zh':
					this.intl.lang = 'zh-CN';
					this.intl.locale = zhCN;
					break;

				case 'pt':
					this.intl.lang = 'pt-BR';
					this.intl.locale = ptBR;
					break;

				case 'hi':
					this.intl.lang = 'hi-IN';
					this.intl.locale = hiIN;
					break;

				case 'ja':
					this.intl.lang = 'ja-JP';
					this.intl.locale = jaJP;
					break;

			}

			if (!this.isEmpty(this.intl.lang)) {

				let translations = this.getTranslations(this.intl.lang);
				if (translations)
					this.intl.translations[this.intl.lang] = translations;

			}

			console.info({
				lang: this.intl.lang,
				intl: this.intl.locale,
				translations: this.intl.translations,
			});

			this.locale = lang;
			Locale.use(this.intl.lang, this.intl.locale);
		},
		showRefreshUI(e) {

			// Display a button inviting the user to refresh/reload the app due
			// to an app update being available.
			// The new service worker is installed, but not yet active.
			// Store the ServiceWorkerRegistration instance for later use.
			if (e.detail) {
				this.worker = e.detail;
				this.appUpdateExists = true;
				console.log("Showing refresh button.");
				console.log(this.worker);
			} else {
				console.warn("No worker data found!");
			}
		},
		refreshApp() {

			console.log("skipWaiting started");
			console.log(this.worker);

			// Handle a user tap on the update app button.
			this.appUpdateExists = false;

			// Protect against missing registration.waiting.
			if (!this.worker) {
				console.warn("No worker data found when trying to refresh!");
				return;
			}

			this.worker.postMessage({ type: "SKIP_WAITING" });
			console.log("skipWaiting finished");
		}
	},
	mounted() {

		this.setLang(this.locale);

		this.$store.commit('setSettings', this.settings);

		let vh = window.innerHeight * 0.01;
		document.documentElement.style.setProperty('--van-vh', `${vh}px`);

		this.emitter.on('setLang', (lang) => {
			console.log('setLang', lang);
			this.locale = lang;
		});

		this.emitter.on('setPageTitle', (title) => {
			console.log('setPageTitle', title);
			this.title = title;
		});

		this.emitter.on('setSettings', (settings) => {
			console.log('setSettings', settings);
			this.settings = settings;
		});

		this.emitter.on('playSound', (name) => {

			let settings = this.$store.getters.getSettings;

			console.info('emitter::playSound', {
				audio: name,
				mute_ui_sound: settings.mute_ui_sound,
				sound_level: settings.sound_level
			});

			if (!settings.mute_ui_sound) {
				this.playSound(name, settings.sound_level);
			}
		});

		this.getSounds([
			'alert',
			'camera',
			'dial-tone',
			'dial-tone-busy',
			'dial-tone-call',
			'dial-tone-normal',
			'incoming-call',
			'incoming-droped',
			'message-deleted',
			'message-new',
			'not-allowed',
			'notification',
			'out-of-service',
			'short-kiss',
			'success-fanfare',
			'switch',
			'tick',
		]).then((list) => this.sounds = list);

		this.emitter.on('showMessage', (data) => {

			let position = 'center';
			if (!this.isEmpty(data.position))
				position = data.position;

			if (!this.isEmpty(data.message)) {
				if (data.type == 'success')
					showSuccessToast({message: data.message, position: position});
				else if (data.type == 'error')
					showFailToast({message: data.message, position: position});
				else if (data.type == 'loading')
					showLoadingToast({message: data.message, position: position});
				else
					showToast(data.message);
			}
		});

		this.emitter.on('showNotify', (data) => {

			let position = 'top';
			if (!this.isEmpty(data.position))
				position = data.position;

			let color = null;
			if (!this.isEmpty(data.color))
				color = data.color;

			let background = null;
			if (!this.isEmpty(data.background))
				background = data.background;

			let duration = 1000;
			if (!this.isEmpty(data.duration))
				duration = data.duration;

			if (!this.isEmpty(data.message)) {
				if (data.type == 'primary')
					showNotify({ message: data.message, type: 'primary', position: position, color: color, background: background, duration: duration });
				else if (data.type == 'success')
					showNotify({ message: data.message, type: 'success', position: position, color: color, background: background, duration: duration });
				else if (data.type == 'danger')
					showNotify({ message: data.message, type: 'danger', position: position, color: color, background: background, duration: duration });
				else if (data.type == 'warning')
					showNotify({ message: data.message, type: 'warning', position: position, color: color, background: background, duration: duration });
				else
					showNotify(data.message);
			}
		});

		/*if (!this.isEmpty(this.$store.getters.getSettings)) {
			let color_scheme = this.$store.getters.getSettings.color_scheme;
			console.info(color_scheme);
			CommonService.setScheme(color_scheme);
		}*/
	},
	watch: {
		locale: {
			handler: function(value, oldValue) {

				if (value !== oldValue)
					this.setLang(value);

			},
			deep: true
		},
		'settings.color_scheme': function(value, oldValue) {
			let scheme = (value == "dark") ? "dark" : "light";
			theme.value = scheme;
			console.log('Prefers color changed: ' + theme.value + ' scheme');
			CommonService.setScheme(theme.value);
		},
	},
	computed: {
		routeName() {
			return this.$route.name;
		},
		usedWelcomeLayout() {
			return this.inArray(this.routeName, ['sign-in', 'sign-up', 'login', 'logout', 'restore', 'not-found', 'status']);
		},
	},
	beforeUnmount() {

	},
});
</script>

<style lang="scss">
@import "@/assets/styles/app.scss";
</style>