import { createApp } from 'vue';
import App from './App.vue'
import * as vant from 'vant'; // https://vant-ui.github.io/vant/#/en-US/quickstart
import store from "./store";
import router from "./router";
import http from "./http";
import mitt from 'mitt';
import '@/services/RegisterServiceWorker.js';
import CommonService from '@/services/CommonService.js';
import 'vant/lib/index.css';

const emitter = mitt();
const app = createApp(App)
	.use(vant)
	.use(store)
	.use(router, http, CommonService);

app.config.globalProperties.emitter = emitter;
app.config.productionTip = false;

app.mount('#app');
